import Head from 'next/head';
import { Box, Flex } from '@chakra-ui/react';

import {
  Header,
  HomePageIntro,
  Features,
  PlatformUses,
  SneakPeak,
  Gallery,
  Footer,
  Testimonials,
} from '@components';

const HomePage: React.FC = () => {
  return (
    <>
      <Head>
        <title>Valearnis - Tutoring | Grade improvement | Homeschooling</title>
        <meta
          name="description"
          content="Valearnis is a revolutionary educational platform for Australian secondary school students. We provide a tutoring alternative, grade improvement, and even homeschooling support in a modern, student-centric ecosystem. Come learn with us!"
        />
      </Head>
      <Header />
      <Flex as="main" direction="column" minH="100vh" overflow="hidden">
        <HomePageIntro />
        <Box
          minH={'100vh'}
          px="35px"
          color="#fff"
          bg="linear-gradient(#1B4DB2, #44229C)"
        >
          <Features />
          <PlatformUses />
          <Testimonials />
          <SneakPeak />
          <iframe
            src="https://player.vimeo.com/video/827614624?h=ca117ba882"
            width="1200"
            height="675"
            style={{
              width: '100%',
              maxHeight: '56.25vw',
              margin: '2rem auto 0',
            }}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
          <Footer />
        </Box>
      </Flex>
    </>
  );
};

export default HomePage;
